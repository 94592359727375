.wrapper {
  background-color: #f6f6f6;
  .header {
    padding: 0.6rem 0.4rem 0;
    height: 2rem;
    box-sizing: border-box;
    background: url(../../assets/img_sign.png) no-repeat;
    background-position: 4.67rem 0.2rem;
    background-size: 2.4rem 1.68rem;
    .title {
      font-size: 0.4rem;
      font-weight: 500;
      color: #333333;
      line-height: 0.5rem;
    }
    .info {
      margin: 0.2rem 0 0;
      width: 312px;
      font-size: 0.28rem;
      color: #666666;
      line-height: 1.4;
    }
  }
  .form {
    background-color: #fff;
    border-radius: 0.3rem 0.3rem 0 0;
    padding: 0.24rem 0;
  }
  .block {
    padding: 0.3rem 0.4rem;
    display: flex;
    position: relative;
  }
  .block2 {
    padding: 0.3rem 0.4rem;
  }
  .label {
    width: 1.68rem;
    height: 0.44rem;
    flex-shrink: 0;
    font-size: 0.3rem;
    font-weight: 500;
    color: #333333;
    line-height: 0.44rem;
    &.label2 {
      display: block;
    }
  }
  .input {
    width: 100%;
    height: 0.44rem;
    font-size: 0.3rem;
    color: #333;
    border: none;
    &::placeholder {
      color: #999;
    }
  }
  .textarea {
    margin-top: 0.3rem;
    width: 100%;
    height: 2.76rem;
    padding: 0.24rem 0.32rem;
    background: #FFFFFF;
    border-radius: 0.02rem;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    font-size: 0.3rem;
    color: #333;
    &::placeholder {
      color: #999;
    }
  }
  .uploadImg {
    width: 1.2rem;
    height: 1.2rem;
    object-fit: cover;
  }
  .inputFile {
    position: absolute;
    right: 0;
    top: 0.06rem;
    width: 1.2rem;
    height: 0.88rem;
    opacity: 0;
    z-index: 2;
  }
  .uploadBtn {
    position: absolute;
    right: 0;
    top: 0.06rem;
    width: 1.2rem;
    height: 0.88rem;
    background: url(../../assets/img_upload.png) no-repeat center center/0.4rem 0.35rem;
  }
  .sentBtn {
    position: absolute;
    right: 0;
    top: 0.2rem;
    height: 0.64rem;
    padding: 0 0.4rem;
    color: #FC5301;
    font-size: 0.3rem;
    line-height: 0.64rem;
  }
  .btnWrap {
    padding: 0.3rem 0.4rem 0.6rem;
    .btn {
      width: 100%;
      height: 0.88rem;
      background: linear-gradient(98deg, #FF8B03 0%, #FD5301 100%);
      border-radius: 0.04rem;
      font-size: 0.36rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 0.88rem;
      border: none;
    }
  }
}