/* 清除浮动 */
.clearfix:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
}

/* 单行溢出 */
.inaline {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 两行溢出 */
.intwoline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  -webkit-box-orient: vertical;
}