.wrapper {
  padding: 2rem 0.3rem 0.3rem;
  display: flex;
  align-content: center;
  flex-direction: column;
  text-align: center;
  .text1 {
    font-size: 1rem;
    color: #999;
    margin-bottom: 0.3rem;
  }
  .text2 {
    font-size: 0.3rem;
    color: #666;
  }
}