.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  .img {
    width: 5.25rem;
  }
  .text {
    font-size: 0.42rem;
    font-weight: 500;
    color: #333333;
  }
}